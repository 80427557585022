import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93')
];

export const server_loads = [2,8,9,10,11,12,13,14,4,5,6];

export const dictionary = {
		"/(app)": [16,[2],[3]],
		"/(app)/admin": [47,[2,7],[3]],
		"/(app)/admin/cli-policies": [~48,[2,7],[3]],
		"/(app)/admin/cli-policies/[policyID=integer]": [~49,[2,7,8],[3]],
		"/(app)/admin/cli-policies/[policyID=integer]/licenses": [~50,[2,7,8],[3]],
		"/(app)/admin/org": [~51,[2,7],[3]],
		"/(app)/admin/org/[org]/policies": [~52,[2,7,9],[3]],
		"/(app)/admin/org/[org]/policies/[id]": [~53,[2,7,9],[3]],
		"/(app)/admin/org/[org]/services": [~54,[2,7,9],[3]],
		"/(app)/admin/org/[org]/users": [~55,[2,7,9],[3]],
		"/(app)/admin/org/[org]/users/new": [56,[2,7,9],[3]],
		"/(app)/admin/services": [~57,[2,7],[3]],
		"/(app)/admin/settings": [58,[2,7],[3]],
		"/(app)/admin/users": [59,[2,7],[3]],
		"/(app)/admin/users/[user]": [~60,[2,7],[3]],
		"/auth/cli": [~66],
		"/auth/cli/success": [67],
		"/auth/email-sent": [68],
		"/auth/login": [~69],
		"/auth/login/password": [70],
		"/auth/login/set-password": [71],
		"/auth/login/signup": [~72],
		"/auth/logout": [~73],
		"/auth/reset-password": [74],
		"/auth/reset-password/reset": [75],
		"/(app)/org/[org]": [~61,[2,10],[3]],
		"/(app)/org/[org]/policies": [62,[2,10],[3]],
		"/(app)/org/[org]/services": [63,[2,10],[3]],
		"/privacy-policy": [76],
		"/public": [~77],
		"/public/[service]": [78,[11]],
		"/public/[service]/animals": [~79,[11,12]],
		"/public/[service]/animals/[id]/(tabs)": [80,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/dna-samples": [~81,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/ebv": [82,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/genetic-tests": [83,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/performance": [~84,[11,12,13]],
		"/public/[service]/animals/[id]/(tabs)/progeny": [85,[11,12,13]],
		"/public/[service]/catalogs": [~86,[11]],
		"/public/[service]/catalogs/[catalogID]": [87,[11,14]],
		"/public/[service]/catalogs/[catalogID]/ebvs": [~88,[11,14]],
		"/public/[service]/ebv/references/[id]": [~89,[11]],
		"/public/[service]/help/ebv": [90,[11,15]],
		"/public/[service]/members": [91,[11]],
		"/public/[service]/sale-catalogs": [~92,[11]],
		"/(app)/settings": [~64,[2],[3]],
		"/(app)/settings/cli": [~65,[2],[3]],
		"/terms-and-conditions": [93],
		"/(app)/[service]": [17,[2,4],[3]],
		"/(app)/[service]/accounting/invoices": [~19,[2,4],[3]],
		"/(app)/[service]/account": [18,[2,4],[3]],
		"/(app)/[service]/animals": [~20,[2,4],[3]],
		"/(app)/[service]/animals/[id]": [21,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/dna-samples": [22,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/ebv": [23,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/genetic-tests": [24,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/performance": [25,[2,4,5],[3]],
		"/(app)/[service]/animals/[id]/progeny": [26,[2,4,5],[3]],
		"/(app)/[service]/disposals": [~27,[2,4],[3]],
		"/(app)/[service]/disposals/[id]": [28,[2,4],[3]],
		"/(app)/[service]/genetic-trends": [29,[2,4],[3]],
		"/(app)/[service]/matings": [30,[2,4],[3]],
		"/(app)/[service]/matings/[id]": [31,[2,4],[3]],
		"/(app)/[service]/qaqc": [32,[2,4],[3]],
		"/(app)/[service]/registrations": [33,[2,4],[3]],
		"/(app)/[service]/registrations/[id]": [34,[2,4],[3]],
		"/(app)/[service]/sale-catalogs": [35,[2,4],[3]],
		"/(app)/[service]/sale-catalogs/[catalogID]": [36,[2,4],[3]],
		"/(app)/[service]/test-requests": [~37,[2,4],[3]],
		"/(app)/[service]/test-requests/[requestID]": [38,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/genetic-tests": [39,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/genotype-samples": [40,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/mbv": [~41,[2,4,6],[3]],
		"/(app)/[service]/test-requests/[requestID]/parentage": [42,[2,4,6],[3]],
		"/(app)/[service]/transfers": [43,[2,4],[3]],
		"/(app)/[service]/transfers/[id]": [44,[2,4],[3]],
		"/(app)/[service]/upload": [45,[2,4],[3]],
		"/(app)/[service]/upload/[uploadID]": [46,[2,4],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';